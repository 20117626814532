import React, { useEffect, useState } from "react";
import MapDocumentModal from "../updatedocuments/mapDocumentsModal";
import { Paper } from "@mui/material";
import "./MapDocumentsNewPage.scss";
import Setparamtermodal from "./Setparamtermodal";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { logOut } from "../../../config/user";
import { CaseServices } from "../../../services/CaseServices";
import { CircularProgress } from "@mui/material";
import EditIcon from "../../../assets/editIcon.svg";
import { DateTimePicker } from "../../../utils/dateTimePicker";
import CountdownTimer from "../../../utils/countDownTimer";
import { useSelector } from "react-redux";

const MapDocumentsNewPage = ({
  refreshPage,
  setPaper,
  caseId,
  setValue,
  functionalityType,
  csProcedures,
}) => {
  const [caseProcedures, setCaseProcedures] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [documents, setDocuments] = useState({});
  const [admissionDocuments, setAdmissionDocuments] = useState(null);
  const [duringTreatmentDocuments, setDuringTreatmentDocuments] =
    useState(null);
  const [onDischargeDocuments, setOnDischargeDocuments] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const [editAdmissionDocument, setEditAdmissionDocument] = useState(null);
  const currentRole = useSelector((state) => state.currentRole.value);

  const handleRemove = async (header, idx, caseId, documentId) => {
    console.log("handle Remove");
    if (caseId) {
      try {
        const response = await CaseServices.deleteDocument(documentId);
        await getDefaultsDocument();
      } catch (error) {
        if (error?.response?.status === 401) {
          logOut();
        } else {
          window.alert(error);
        }
      }
    } else {
      let stageDocuments = { ...documents };
      stageDocuments[`${header}`].splice(idx, 1);
      setDocuments(stageDocuments);
    }
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      let destructuredDocs = [
        ...documents.ADMISSIONDOCUMENT,
        ...documents.DURINGTREATMENTDOCUMENT,
        ...documents.ONDISCHARGEDOCUMENT,
      ];
      if (destructuredDocs.length < 1) {
        window.alert("Please map at least one document");
        setIsSubmitting(false);
        return;
      }
      await CaseServices.addMapDocuments(destructuredDocs, caseId);
      window.alert("Documents mapped successfully");
      setValue("ADD DOCUMENTS");
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
    }
    setIsSubmitting(false);
  };

  const handleUpdate = async () => {
    setIsSubmitting(true);
    try {
      let destructuredDocs = [
        ...documents.ADMISSIONDOCUMENT,
        ...documents.DURINGTREATMENTDOCUMENT,
        ...documents.ONDISCHARGEDOCUMENT,
      ];

      if (destructuredDocs.length < 1) {
        window.alert("Please map at least one document");
        setIsSubmitting(false);
        return;
      }
      await CaseServices.updateMappedDocuments(destructuredDocs, caseId);
      refreshPage();
      window.alert("Documents updated successfully");
      setValue("UPDATE DOCUMENTS");
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
    }
    setIsSubmitting(false);
  };

  const handleEdit = (docId) => {
    if (docId === editAdmissionDocument) {
      setEditAdmissionDocument(null);
    } else {
      setEditAdmissionDocument(docId);
    }
  };
  function updateDeadline(date, name, idx) {
    documents[`${name}`][`${idx}`]["deadline"] = date;
    setDocuments({ ...documents });
  }
  async function getDefaultsDocument() {
    try {
      setIsLoading(true);
      if (functionalityType === "EDIT") {
        let documents = await CaseServices.getMappedDocuments(caseId);
        if (documents?.data?.mappedDocuments && documents?.data?.count > 0) {
          setDocuments(documents?.data?.mappedDocuments);
        }
        if (documents?.data?.count == 0) {
          let procedureIds = await csProcedures.map((ids) => ids.procedureId);
          documents = await CaseServices.defaultDocuments(procedureIds);
          // Added deadline inside default documents
          let j = 1;
          const currentDate = new Date();
          const deadline = new Date(currentDate.getTime() + 4 * 60 * 60 * 1000);
          for (let i of documents?.data?.defaultCaseDocuments
            .ADMISSIONDOCUMENT) {
            i["deadline"] = deadline;
          }
          setDocuments(documents?.data?.defaultCaseDocuments);
        }
      } else {
        let procedures = await CaseServices.getCaseProcedures(caseId); // caseId
        if (procedures?.data?.caseProcedures) {
          setCaseProcedures(procedures?.data?.caseProcedures);
          let procIds = procedures.data.caseProcedures.map(
            (proc) => proc.procedureId
          );
          let res = await CaseServices.defaultDocuments(procIds);
          if (res?.data?.defaultCaseDocuments)
            setDocuments(res?.data?.defaultCaseDocuments);
        }
      }
      setIsLoading(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
      setIsLoading(false);
    }
  }
  const formatData = (data, updateMethod) => {
    const formattedDocument = [];
    const ids = [];
    for (let i of data) {
      const values = [];
      for (let j of data) {
        if (
          i.procedureId === j.procedureId &&
          ids.findIndex((item) => item === i.procedureId) === -1
        ) {
          values.push(j);
        }
      }
      if (values.length > 0) {
        ids.push(i.procedureId);
        formattedDocument.push({
          procedureId: i.procedureId,
          values: values,
        });
      }
    }
    updateMethod(formattedDocument);
  };
  useEffect(() => {
    getDefaultsDocument();
  }, []);
  useEffect(() => {
    if (
      documents &&
      documents?.ADMISSIONDOCUMENT &&
      documents?.ADMISSIONDOCUMENT.length > 0
    ) {
      formatData(documents?.ADMISSIONDOCUMENT, setAdmissionDocuments);
    }
    if (
      documents &&
      documents?.DURINGTREATMENTDOCUMENT &&
      documents?.DURINGTREATMENTDOCUMENT.length > 0
    ) {
      formatData(
        documents?.DURINGTREATMENTDOCUMENT,
        setDuringTreatmentDocuments
      );
    }
    if (
      documents &&
      documents?.ONDISCHARGEDOCUMENT &&
      documents?.ONDISCHARGEDOCUMENT.length > 0
    ) {
      formatData(documents?.ONDISCHARGEDOCUMENT, setOnDischargeDocuments);
    }
  }, [documents]);

  return (
    <Paper className="map-documents-new-box">
      <div className="btn-container" style={{ justifyContent: "flex-start" }}>
        <select
          name=""
          className="outline-btn"
          style={{ width: "167px", display: "block" }}
        >
          <option value="">SPECIALITY ID</option>
        </select>
        <select
          name=""
          className="outline-btn"
          style={{ width: "171px", display: "block" }}
        >
          <option value="">PROCEDURE ID</option>
        </select>
        <select
          name=""
          className="outline-btn"
          style={{ width: "200px", display: "block" }}
        >
          <option value="">PROCEDURE NAME</option>
        </select>
        <select
          name=""
          className="outline-btn"
          style={{ width: "137px", display: "block" }}
        >
          <option value="">IMPLANTS</option>
        </select>
        <select
          name=""
          className="outline-btn"
          style={{ width: "184px", display: "block" }}
        >
          <option value="">STRATIFICATION</option>
        </select>

        <Setparamtermodal
          caseId={caseId}
          setDocuments={setDocuments}
          documents={documents}
        />

        <ErrorOutlineIcon style={{ height: "32px", color: "#017C57" }} />
      </div>
      <hr
        style={{
          color: "#E7E7E7",
          marginBottom: "32px",
        }}
      />
      {!isLoading ? (
        <>
          <table className="table-container">
            <thead>
              <tr className="table-head">
                <th align="start" className="cell">
                  Sr. No.
                </th>
                <th className="cell">PROCEDURE ID</th>
                <th className="cell" align="center"></th>
                <th className="cell" align="center">
                  Deadline
                </th>

                <th className="cell">Case Stage</th>
                <th className="cell">Document Name</th>
                <th className="cell">Action</th>
              </tr>
            </thead>
            <tr>
              <td colSpan={6}>
                <div className="heading">
                  Admission Documents{" "}
                  <MapDocumentModal
                    documentType="map admission document"
                    caseProcedures={csProcedures}
                    caseId={caseId}
                    getDefaultsDocument={getDefaultsDocument}
                  />
                </div>
              </td>
            </tr>
            <tbody>
              {admissionDocuments ? (
                admissionDocuments.map((document, idx) => {
                  return (
                    <>
                      <tr>
                        <td colSpan={6}>
                          <div style={{ fontWeight: "600", fontSize: "16px" }}>
                            {document?.procedureId}
                          </div>
                        </td>
                      </tr>
                      {document.values.map((doc, idx) => {
                        return (
                          <tr
                            key={idx}
                            className="table-body"
                            style={
                              idx % 2 !== 0
                                ? {
                                    backgroundColor: "#F9F9F9",
                                    borderRadius: "8px",
                                  }
                                : {}
                            }
                          >
                            <td className="cell"> {idx + 1}.</td>
                            <td className="cell">{doc.procedureId}</td>
                            <td className="cell" align="center">
                              {" "}
                              {doc.required ? (
                                <button className="required-btn">
                                  <ErrorOutlineIcon
                                    sx={{
                                      height: "11px",
                                      width: "11px",
                                    }}
                                  />
                                  Required
                                </button>
                              ) : (
                                <></>
                              )}
                            </td>

                            <td
                              className="cell"
                              style={{
                                fontSize: "16px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "56px",
                                gap: "4px",
                              }}
                            >
                              {doc.id === editAdmissionDocument ? (
                                <DateTimePicker
                                  handleChange={updateDeadline}
                                  deadline={doc?.deadline}
                                  name="ADMISSIONDOCUMENT"
                                  idx={idx}
                                  disabled={doc.id !== editAdmissionDocument}
                                />
                              ) : (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <div style={{ fontSize: "12px" }}>
                                    Time Remaining :
                                  </div>
                                  <CountdownTimer
                                    deadline={doc.deadline}
                                    color="#000000"
                                  />
                                </div>
                              )}
                              <img
                                src={EditIcon}
                                alt="edit-icon"
                                onClick={() => handleEdit(doc.id)}
                              />
                            </td>
                            {/* <td className="cell" style={{fontSize:'16px'}}>Time Remaining: <b style={{color:'#191919'}}>{doc.uploadTimeLimit}</b> <EditOutlinedIcon style={{color:'#017C57', height:'16px'}} onpress={handletime}/></td> */}
                            <td className="cell"> {doc.caseStage}</td>
                            <td className="cell"> {doc.documentName}</td>
                            <td className="cell">
                              <DeleteOutlinedIcon
                                sx={{ color: "#CF3636" }}
                                onClick={() => {
                                  handleRemove(
                                    "ADMISSIONDOCUMENT",
                                    idx,
                                    doc.caseId,
                                    doc.id
                                  );
                                }}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  );
                })
              ) : (
                <tr className="table-body">
                  <td colSpan="7" className="cell">
                    No document found
                  </td>
                </tr>
              )}

              <tr>
                <td colSpan={6}>
                  <div className="heading">
                    Enhancement Documents
                    <MapDocumentModal
                      documentType="map during treatment document"
                      caseProcedures={csProcedures}
                      caseId={caseId}
                      refreshPage={refreshPage}
                    />
                  </div>
                </td>
              </tr>
              {duringTreatmentDocuments ? (
                duringTreatmentDocuments.map((document, idx) => {
                  return (
                    <>
                      <tr>
                        <td colSpan={6}>
                          <div style={{ fontWeight: "600", fontSize: "16px" }}>
                            {document.procedureId}
                          </div>
                        </td>
                      </tr>
                      {document.values.map((doc, idx) => {
                        return (
                          <tr
                            key={idx}
                            className="table-body"
                            style={
                              idx % 2 !== 0
                                ? {
                                    backgroundColor: "#F9F9F9",
                                    borderRadius: "8px",
                                  }
                                : {}
                            }
                          >
                            <td className="cell"> {idx + 1}.</td>
                            <td className="cell">{doc.procedureId}</td>
                            <td className="cell" align="center">
                              {" "}
                              {doc.required ? (
                                <button className="required-btn">
                                  <ErrorOutlineIcon
                                    sx={{
                                      height: "11px",
                                      width: "11px",
                                    }}
                                  />
                                  Required
                                </button>
                              ) : (
                                <></>
                              )}
                            </td>

                            <td
                              className="cell"
                              style={{
                                fontSize: "16px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "56px",
                                gap: "4px",
                              }}
                            >
                              {doc.id === editAdmissionDocument ? (
                                <DateTimePicker
                                  handleChange={updateDeadline}
                                  deadline={doc?.deadline}
                                  name="DURINGTREATMENTDOCUMENT"
                                  idx={idx}
                                  disabled={doc.id !== editAdmissionDocument}
                                />
                              ) : (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <div style={{ fontSize: "12px" }}>
                                    Time Remaining :
                                  </div>
                                  <CountdownTimer
                                    deadline={doc.deadline}
                                    color="#000000"
                                  />
                                </div>
                              )}
                              <img
                                src={EditIcon}
                                alt="edit-icon"
                                onClick={() => handleEdit(doc.id)}
                              />
                            </td>

                            <td className="cell"> {doc.caseStage}</td>
                            <td className="cell"> {doc.documentName}</td>
                            <td className="cell">
                              <DeleteOutlinedIcon
                                sx={{ color: "#CF3636" }}
                                onClick={() => {
                                  handleRemove("DURINGTREATMENTDOCUMENT", idx);
                                }}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  );
                })
              ) : (
                <tr className="table-body">
                  <td colSpan="7" className="cell">
                    No document found
                  </td>
                </tr>
              )}
              <tr>
                <td colSpan={6}>
                  <div className="heading">
                    On Discharge Documents
                    <MapDocumentModal
                      documentType="map discharge document"
                      caseProcedures={csProcedures}
                      caseId={caseId}
                      refreshPage={refreshPage}
                      getDefaultsDocument={getDefaultsDocument}
                    />
                  </div>
                </td>
              </tr>
              {onDischargeDocuments ? (
                onDischargeDocuments.map((document, idx) => {
                  return (
                    <>
                      <tr>
                        <td colSpan={6}>
                          <div style={{ fontWeight: "600", fontSize: "16px" }}>
                            {document.procedureId}
                          </div>
                        </td>
                      </tr>
                      {document.values.map((doc, idx) => {
                        return (
                          <tr
                            key={idx}
                            className="table-body"
                            style={
                              idx % 2 !== 0
                                ? {
                                    backgroundColor: "#F9F9F9",
                                    borderRadius: "8px",
                                  }
                                : {}
                            }
                          >
                            <td className="cell"> {idx + 1}.</td>
                            <td className="cell">{doc.procedureId}</td>
                            <td className="cell" align="center">
                              {" "}
                              {doc.required ? (
                                <button className="required-btn">
                                  <ErrorOutlineIcon
                                    sx={{
                                      height: "11px",
                                      width: "11px",
                                    }}
                                  />
                                  Required
                                </button>
                              ) : (
                                ""
                              )}
                            </td>

                            <td
                              className="cell"
                              style={{
                                fontSize: "16px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "56px",
                                gap: "4px",
                              }}
                            >
                              {doc.id === editAdmissionDocument ? (
                                <DateTimePicker
                                  handleChange={updateDeadline}
                                  deadline={doc?.deadline}
                                  name="ONDISCHARGEDOCUMENT"
                                  idx={idx}
                                  disabled={doc.id !== editAdmissionDocument}
                                />
                              ) : (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <div style={{ fontSize: "12px" }}>
                                    Time Remaining :
                                  </div>
                                  <CountdownTimer
                                    deadline={doc.deadline}
                                    color="#000000"
                                  />
                                </div>
                              )}
                              <img
                                src={EditIcon}
                                alt="edit-icon"
                                onClick={() => handleEdit(doc.id)}
                              />
                            </td>
                            <td className="cell"> {doc.caseStage}</td>
                            <td className="cell"> {doc.documentName}</td>
                            <td className="cell">
                              <DeleteOutlinedIcon
                                sx={{ color: "#CF3636" }}
                                onClick={() => {
                                  handleRemove("ONDISCHARGEDOCUMENT", idx);
                                }}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  );
                })
              ) : (
                <tr className="table-body">
                  <td colSpan="7" className="cell">
                    No document found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="btn-container">
            {isSubmitting ? (
              <div className="flex justify-center">
                <CircularProgress />
              </div>
            ) : (
              <button
                onClick={() => {
                  if (currentRole.role.roleName !== "TeamB") {
                    functionalityType === "EDIT"
                      ? handleUpdate()
                      : handleSubmit();
                  }
                }}
                disabled={isSubmitting}
                style={
                  currentRole.role.roleName === "TeamB"
                    ? { cursor: "not-allowed" }
                    : {}
                }
                className="filled-btn"
              >
                SAVE DETAILS
              </button>
            )}
          </div>
        </>
      ) : (
        <div style={{ width: "100%", textAlign: "center", marginTop: "40px" }}>
          <CircularProgress />
        </div>
      )}
    </Paper>
  );
};

export default MapDocumentsNewPage;
