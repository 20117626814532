import { useState, useEffect } from "react";
import CloseIcon from "../../../assets/crossIcon.svg";
import { CaseServices } from "../../../services/CaseServices";
import AddIcon from "../../../assets/addMoreIcon.svg";
import { CircularProgress } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import SuggestionBoxUtil from "../../../utils/SuggestionBoxUtil";
import { logOut } from "../../../config/user";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
const EditStatusModal = ({ setEditStatus, caseId }) => {
  const [documents, setDocuments] = useState(null);
  const [documentStatus, setDocumentStatus] = useState(null);
  const [rejectionReason, setRejectionReason] = useState(null);
  const [mappedDocuments, setMappedDocuments] = useState([]);
  const [inputValue, setInputValue] = useState(null);
  const [selectedMapping, setSelectedMapping] = useState(null);
  const currentRole = useSelector((state) => state.currentRole.value);
  const navigate = useNavigate();
  const getDocumentDetails = async () => {
    try {
      const response = await CaseServices.getLatestCaseStage(caseId);
      setDocuments(response.data);
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
    }
  };
  const handleCheckBoxChange = (documentId) => {
    const formattedDocument = documentStatus.map((document) => {
      if (document.documentId === documentId) {
        return {
          documentId: document.documentId,
          status: !document.status,
        };
      } else {
        return document;
      }
    });

    setDocumentStatus(formattedDocument);
  };

  const handleAutoSuggestion = (selected, e) => {
    console.log("selected value", selected);
    console.log("event", e);
    setSelectedMapping({
      ...selected,
      documentType: documents?.documents[0]?.documentType,
    });
    //  console.log('onchange caled', selected)
  };
  const handleMappedDocuments = () => {
    if (inputValue) {
      const documentDetails = documents?.currnetSubMasterStage[0]?.hsaStage
        ?.toLowerCase()
        .includes("enhancement")
        ? {
            enhancementId: documents?.documents[0]?.enhancementId,
            caseStatge: documents?.documents[0]?.caseStage,
            documentType: documents?.documents[0]?.documentType,
            documentName: inputValue,
            required: true,
          }
        : {
            caseStatge: documents?.documents[0]?.caseStage,
            documentType: documents?.documents[0]?.documentType,
            documentName: inputValue,
            required: true,
          };
      setMappedDocuments([...mappedDocuments, documentDetails]);
      setInputValue(null);
    } else if (selectedMapping) {
      if (
        documents?.currnetSubMasterStage[0]?.hsaStage
          ?.toLowerCase()
          .includes("enhancement")
      ) {
        setMappedDocuments([
          ...mappedDocuments,
          {
            ...selectedMapping,
            enhancementId: documents?.documents[0]?.enhancementId,
          },
        ]);
      } else {
        setMappedDocuments([...mappedDocuments, selectedMapping]);
      }

      setSelectedMapping(null);
      setInputValue(null);
    }
  };
  const handleRemoveDocument = (index) => {
    let changedArray = mappedDocuments.filter((_, idx) => idx !== index);
    setMappedDocuments(changedArray);
  };
  const handleUpdateStatus = async () => {
    const data = documentStatus?.map((document) => {
      if (document.status) {
        return {
          id: document.documentId,
          status: "APPROVED",
        };
      } else {
        return {
          id: document.documentId,
          status: "REJECTED",
          rejectionReason: rejectionReason,
        };
      }
    });
    const postData = data.filter((document) => document.status === "REJECTED");
    if (postData.length > 0 || mappedDocuments.length > 0) {
      try {
        if (postData.length > 0) {
          const response = await CaseServices.updateMultipleDocumentStatus(
            postData,
            currentRole?.role?.roleName
          );

          window.alert(response.data.message);
        }
        if (mappedDocuments.length > 0) {
          const mappedDocumentResponse =
            await CaseServices.updateMappedDocuments(
              mappedDocuments,
              documents?.documents[0]?.caseId
            );
          window.alert(mappedDocumentResponse.data.message);
        }
        navigate("/dashboard/ActiveCases");
      } catch (error) {
        if (error?.response?.status === 401) {
          logOut();
        } else {
          window.alert(error);
        }
      }
    } else {
      try {
        const postStageData = {
          hsaStage: documents?.documents[0]?.documentType
            .toLowerCase()
            .includes("admission")
            ? "Admission Approved"
            : documents?.documents[0]?.documentType
                .toLowerCase()
                .includes("enhancement")
            ? "Enhancement Approved"
            : documents?.documents[0]?.documentType
                .toLowerCase()
                .includes("discharge")
            ? "Claim Approved"
            : "",
          commandCeneterStage: documents?.documents[0]?.documentType
            .toLowerCase()
            .includes("admission")
            ? "Admission Approved"
            : documents?.documents[0]?.documentType
                .toLowerCase()
                .includes("enhancement")
            ? "Enhancement Approved"
            : documents?.documents[0]?.documentType
                .toLowerCase()
                .includes("discharge")
            ? "Discharge Approved"
            : "",
          nextStepUser: "Hospital Sub Admin (HSA)",
          caseDetails: [
            {
              query: "",
              queryResponse: rejectionReason,
            },
          ],
        };
        const stageResponse = await CaseServices.updateSubMasterStage(
          documents?.documents[0]?.caseId,
          postStageData
        );
        window.alert(stageResponse.data.message);
        setEditStatus(false);
        navigate("/dashboard/ActiveCases");
      } catch (error) {
        if (error?.response?.status === 401) {
          logOut();
        } else {
          window.alert(error);
        }
      }
    }
  };
  useEffect(() => {
    getDocumentDetails();
  }, []);
  useEffect(() => {
    console.log("input value:", inputValue);
  }, [inputValue]);
  console.log("documents:", documents);
  useEffect(() => {
    if (documents?.documents?.length > 0) {
      let formattedDocument = [];
      for (let i of documents?.documents) {
        formattedDocument.push({ documentId: i.id, status: true });
      }
      setDocumentStatus(formattedDocument);
    }
  }, [documents]);
  return (
    <div
      style={{
        maxHeight: "85%",
        width: "60%",
        background: "white",
        position: "relative",
        border: "1px solid #E4E4E4",
        borderRadius: "16px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

        padding: "16px",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "28px",
          right: "32px",
          cursor: "pointer",
        }}
        onClick={() => setEditStatus(false)}
      >
        <img src={CloseIcon} height={30} width={30} alt="close-icon" />
      </div>
      <div className="w-full flex flex-col items-center  ">
        <div className="text-2xl font-semibold text-[#191919]">
          Change case status
        </div>
        <div className="text-[#797979] mt-4">
          {/* This is the current case status */}
          {documents?.currnetSubMasterStage[0]?.commandCenterStage}
        </div>
        <div className="mt-4 w-full max-h-[500px] overflow-y-scroll">
          <div className="w-full p-4">
            <div className="text-xl text-[#191919] font-semibold">
              Uploaded Documents
            </div>
            {documents ? (
              <div className="grid grid-cols-3 gap-2 mt-2 ">
                {documents?.documents?.map((document, idx) => {
                  return (
                    <div
                      key={idx}
                      style={{ border: "1px solid #E4E4E4" }}
                      className="rounded-[8px] flex justify-between items-center px-1 py-2"
                    >
                      <div className="font-medium">{document.documentName}</div>
                      <input
                        type="checkbox"
                        name="document1"
                        className="h-[20px] w-[20px]"
                        onChange={() => handleCheckBoxChange(document.id)}
                      />
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="flex justify-center">
                <CircularProgress />
              </div>
            )}
          </div>
          <div className="w-full p-4">
            <div className="flex justify-between items-center mb-4">
              <div className="text-xl text-[#191919] font-semibold">
                Map New Documents
              </div>
            </div>
            <div className="flex justify-between items-center">
              <div className="w-[70%]">
                <SuggestionBoxUtil
                  className="input-field"
                  api={CaseServices.defaultDocumentForSuggestionBox}
                  // selectedValue={procdure.speciality}
                  handleChange={handleAutoSuggestion}
                  name="documentName"
                  setInputValue={setInputValue}
                />
              </div>
              <div
                className="w-fit flex items-center gap-2 text-sm font-semibold bg-[#017C57] rounded-full px-4 py-2 text-white cursor-pointer"
                onClick={() => {
                  handleMappedDocuments();
                }}
              >
                <img src={AddIcon} alt="add-more-icon" />
                Map Documents
              </div>
            </div>
            {/* {mapDocumentSize.length > 0 &&
              mapDocumentSize.map((document, idx) => {
                return (
                  <div
                    key={idx}
                    className="flex justify-between items-center gap-2"
                  >
                    <div className="w-full">
                      <input
                        type="text"
                        placeholder="Enter Document Name"
                        style={{ border: "1px solid #E4E4E4" }}
                        className="w-full px-2 py-2 mt-3 rounded-[8px] border-0"
                        onChange={(e) => setValue(e.target.value)}
                      />
                    </div>

                    <div
                      className="w-fit h-fit text-white text-sm font-semibold bg-[#017C57] px-8 py-1 rounded-full cursor-pointer"
                      onClick={handleMappedDocuments}
                    >
                      Save
                    </div>
                  </div>
                );
              })} */}
            <div className="mt-2">
              {mappedDocuments.length > 0 &&
                mappedDocuments.map((document, idx) => {
                  return (
                    <div className="flex justify-between items-center mb-1">
                      <div
                        style={{ border: "1px solid #e4e4e4" }}
                        className="w-[80%] rounded-[4px] p-2"
                      >
                        {document?.documentName}
                      </div>
                      <div
                        onClick={() => handleRemoveDocument(idx)}
                        className="cursor-pointer "
                      >
                        <DeleteIcon style={{ color: "red" }} />
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="w-full p-4 ">
            <textarea
              type="text"
              style={{
                width: "100%",
                border: "1px solid #E4E4E4",
                borderRadius: "8px",
                height: "120px",
                padding: "12px",
              }}
              placeholder="Rejection reason"
              onChange={(e) => {
                setRejectionReason(e.target.value);
              }}
            />
          </div>
          <div className="w-full flex justify-center">
            <div
              className="text-white text-sm font-semibold bg-[#017C57] px-4 py-2 rounded-full cursor-pointer"
              onClick={handleUpdateStatus}
            >
              UPDATE CASE STATUS
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditStatusModal;
